body {font-family: "Lato", sans-serif}

.main-head {
  height: 150px;
  background: #fff;
}

.sidenav {
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 20px;
  background-image: url("https://cdn.pixabay.com/photo/2015/07/27/19/20/gas-863196_960_720.jpg");
  background-size: cover;
}

.main {padding: 0px 10px;}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 150px}
}

@media screen and (max-width: 450px) {
  .sidenav {padding-top: 400px}
  .login-form {margin-top: 10%}
  .register-form {margin-top: 10%}
}

@media screen and (min-width: 768px) {
  .main {margin-left: 65%;}
  .login-form {margin-top: 80%;
}
  .register-form {margin-top: 20%;}

  .sidenav {
    width: 60%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }
}

.login-main-text {
  margin-top: 20%;
  padding: 60px;
  color: #fff;
}

.login-main-text h2 {font-weight: 300;}

.btn-black {
  background-color: #000 !important;
  color: #fff;
}

/* Update-VAR live data */

.update-var {
  background-image: linear-gradient(
    to right,
    var(--color-hover, #54b3d6),
    var(--color-hover, #54b3d6) 50%,
    var(--color-default, #000) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.update-var:before {
  content: "";
  background: var(--color-hover, #54b3d6);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.update-var:hover {background-position: 0;}
.update-var:hover::before {width: 100%;}

/* Update-VAR live data */

/*------------------------------------*/
.update-var-2 {
  background-image: linear-gradient(
    to right,
    var(--color-hover, #54b3d6),
    var(--color-hover, #54b3d6) 50%,
    var(--color-default, #000) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  /* display: inline-block; */
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.update-var-2:before {
  content: "";
  background: #54b3d6;
  /* display: block; */
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.update-var-2:hover {background-position: 0;}
.update-var-2:hover::before {width: 100%}

/*------------------------------------*/

/* Alarm-VAR live data */

.alarm-var {
  background-image: linear-gradient(
    to right,
    var(--color-hover, #54b3d6),
    var(--color-hover, #54b3d6) 50%,
    var(--color-measure, #000) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: help;
  font-size: 20px;
}

.alarm-var:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.alarm-var:hover {background-position: 0;}
.alarm-var:hover::before {width: 100%;}

/*------------------------------------*/

.alarm-var-2 {
  background-image: linear-gradient(
    to right,
    var(--color-hover, #54b3d6),
    var(--color-hover, #54b3d6) 50%,
    var(--color-default, #000) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: help;
  font-size: 15px;
}

.alarm-var-2:before {
  content: "";
  background: #54b3d6;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.alarm-var-2:hover {background-position: 0}
.alarm-var-2:hover::before {width: 100%}

/*------------------------------------*/

/* Update-VAR live data */

/* Red Alarm */

.red-alarm {
  background-image: linear-gradient(
    to right,
    var(--color-hover, #54b3d6),
    var(--color-hover, #54b3d6) 50%,
    var(--color-alarm, #ff0000) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  /* display: inline-block;
    padding: 5px 0;
    position: relative; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: help;
  font-size: 20px;
}

.red-alarm:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.red-alarm:hover {background-position: 0}
.red-alarm:hover::before {width: 100%}

/*------------------------------------*/

.red-alarm-2 {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #ff0000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: help;
  font-size: 15px;
}

.red-alarm-2:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.red-alarm-2:hover {background-position: 0}
.red-alarm-2:hover::before {width: 100%}

/*------------------------------------*/

/* Red Alarm */

.plantilla {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #9900ff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 25px;
}

.plantilla:hover {background-position: 0}
.plantilla:hover::before {width: 100%}

.medida-izq-grafica {
  height: 80px;
  width: 130px;
  padding: 0;
}

.plantillaBtn {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #9900ff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 15px;
}

.plantillaBtn.disabled {
  background-image: linear-gradient(
    to right,
    #cccccc,
    #cccccc
  ); /* Cambia los colores a tonos grises */
  cursor: not-allowed; /* Cambia el cursor a not-allowed para indicar que está deshabilitado */
  opacity: 0.5; /* Reduce la opacidad para dar un efecto de deshabilitado */
  pointer-events: none; /* Desactiva los eventos del ratón, haciendo el elemento no clickeable */
}

.plantillaBtn:hover {background-position: 0}
.plantillaBtn:hover::before {width: 100%}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.truncate-text:hover {
  white-space: normal;
  word-wrap: break-word;
}

/* --------------------------------------------------- */
.login {min-height: 100vh}

.bg-image {
  background-image: url("https://cdn.pixabay.com/photo/2015/07/27/19/20/gas-863196_960_720.jpg");
  background-size: cover;
  background-position: center;
}

.login-heading {font-weight: 300}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  background-color: #019dd6;
}

.btn-login:hover {
  background-color: #070024;
  color: #019dd6;
}

.color-logo-claro {color: #019dd6}
.color-logo-oscuro {color: #070024}