@media screen and (max-width: 450px) {
  .wc-responsive { visibility: hidden}
  .h-responsive { height: 10%}
  .h100-responsive {height: 18%}
}

@media screen and (min-width: 450px) {
  .h-responsive {height: 59%}
  .h100-responsive {height: 100%}
}

.highcharts-credits {display: none}
.custom-datepicker {font-size: 11px}